
import {projects} from 'src/app/enum/projects';
/* tslint:disable:no-string-literal */
'use strict';

      /**
       * For the local development purpouse
       */
      //export const serverBase = 'https://localhost:44394';
      /**
       * For the IIS published developemnt
       */
      export const serverBase = 'https://www.account.expertential.net/expertentialNewSignUpAPI';
      //export const signupServerBase = 'https://signup.expertential.net/ExpertentialSignupWAPI';
      export const signupServerBase = 'https://account.expertential.net/expertentialNewSignUpAPI';

      /**
       * For the testing purpouse of the published backend in IIS
       */
      //export const serverBase = 'http://expertentialcrm.expertential.net/CRMWAPI'
/* tslint:enable:no-string-literal */


export const USINGPROJECT: projects = projects.Expertential;
