// Importing libraries
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

// Importing services
import { SignupService } from 'src/app/service/signup.service';
import { AuthService, SocialUser, GoogleLoginProvider, FacebookLoginProvider, LinkedinLoginProvider } from 'ng4-social-login';
import { LoginService } from 'src/app/service/login.service';
import { LoginDetails } from 'src/app/core/loginDetails';
import { TokenDetails } from 'src/app/core/tokenDetails';
import { UserLoginDetails } from 'src/app/core/userLoginDetails';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';

//importing the common model for the config changes
import * as globals from 'src/app/core/common';

//importing the enum model
import { projects } from 'src/app/enum/projects';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { LocationDetails } from 'src/app/core/locationDetails';
import { AuthenticationDetails } from 'src/app/core/authenticationDetails';
import { AuthenticationTypes } from 'src/app/core/authenticationTypes';

declare var OAuth: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [SignupService, LoginService, AuthenticationService]
})
export class LoginComponent implements OnInit {
  //config variables
  usingProject: projects;

  IsExpertiseExchange: boolean = false;

  title = '';
  public user: any = SocialUser;
  registerForm: FormGroup;
  submitted = false;
  fullname: string;
  emailAddress: string;
  errorMessage: string;
  signupType: string;
  invalidLogin: boolean = false;
  displaySignup: boolean = true;

  // Social Media details
  email: string = "";
  firstName: string;
  lastName: string;
  emailField: boolean;
  passwordField: boolean;
  password: string = "";
  existing: boolean = false;

  tokenDetails: TokenDetails;

  //variable for the login type
  loginTypeIsLink: boolean = false;

  linkingemail: string;
  linkingpassword: string;
  linkingsmtype: string;

  showLoading: boolean = false;
  loginwithemail: boolean = false;

  target: string;

  showMymodel: boolean = false;
  IsOwner: boolean = false;
  MessageHeader: string = "";
  Message: string = "";
  AccountType: string = "";

  authenticationTypes: AuthenticationTypes;
  redirectNewUrl: string = "";
  globalParamObj: any;
  authId: number;

  // Authentication Cookies 
  cookieValue = '';

  constructor(
    private socialAuthService: AuthService, private formBuilder: FormBuilder,
    private router: Router, private readonly LocationObj: Location, private signupService: SignupService, private route: ActivatedRoute
    , private loginService: LoginService, private authenticationService: AuthenticationService,
    private cookieService: CookieService) {
  }

  ngOnInit() {
    this.usingProject = globals.USINGPROJECT;
    this.IsExpertiseExchange = this.usingProject == projects.ExpertiseExchange;



    this.target = this.IsExpertiseExchange ? "_blank" : "_self";

    this.LocationObj.replaceState("");

    this.fullname = '';
    this.emailAddress = '';
    this.errorMessage = '';
    this.signupType = '';

    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.emailField = false;
    this.passwordField = false;


    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(1)]]
    });

    // Get Root Parameters
    this.route.paramMap.subscribe(params => {
      this.email = params.get('email');
      this.firstName = params.get('firstName');
      this.lastName = params.get('lastName');
      this.signupType = params.get('signupType');

      if (params.get('password')) {
        this.password = params.get('password');
      }

      if (params.get('linkingpassword')) {
        this.linkingpassword = params.get('linkingpassword');
      }

      if (params.get('linkingemail')) {
        this.linkingemail = params.get('linkingemail');
      }

      if (params.get('linkingsmtype')) {
        this.linkingsmtype = params.get('linkingsmtype');
      }

      if (params.get('loginwithemail')) {
        if (params.get('loginwithemail') === "TRUE") {
          this.showLoading = true;
          this.LoginWithEmail(this.email, this.password, this.signupType);
        }
      } else {
        if (this.password != "" && this.email != "") {
          console.log("Performing auto login");
          //Perform auto login
          this.LoginWithEmail(this.email, this.password, 'EMAIL');
        }
      }



    });



    if (this.email === null && this.firstName === null && this.lastName === null) {
      this.emailField = false;
      this.passwordField = false;
    } else {
      this.emailField = true;
      this.passwordField = true;
      this.existing = true;
      /* tslint:disable:no-string-literal */
      //this.registerForm.controls['email'].disable();
      //this.registerForm.controls['password'].disable();
      /* tslint:enable:no-string-literal */
      this.title = 'Select an existing log in method to your expertential dashboard';

    }

    // Checking the signup type
    if (this.signupType === 'LOGIN') {
      this.title = 'Select an existing log in method to your expertential dashboard';
      this.displaySignup = false;
    }
  }

  get registerFormList() { return this.registerForm.controls; }

  logInWithEmailFunction() {
    console.log("Enable");
    this.router.navigate(['login', { signupType: 'LOGIN' }]);
  }

  LogIn(): void {

    this.submitted = true;
    // stop here if form is invalid

    if (this.registerForm.invalid) {
      console.log("Form is invalid");
      return;
    }
    console.log(this.email)
    // Need to implement navigate Expertential site
    this.LoginWithEmail(this.email, this.password, 'EMAIL');
  }

  // Call Facebook API
  FacebookLogin() {
    this.showLoading = true;
    this.showLoading = false;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (userData) => {

        if (userData !== null && userData !== undefined) {
          this.user = userData;
          const splittedName = userData.name.split(' ');
          // this.password = userData.id;
          // Calling the Login proceed function
          this.LoginWithEmail(userData['email'], userData['id'], 'facebook');
        } else {
          console.log('facebookLogin Error');
        }
      });
  }

  // Call Google API
  GoogleLogin() {
    this.showLoading = true;
    this.showLoading = false;
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (userData) => {

        // Response of GOOGLE sign in API
        if (userData !== null && userData !== undefined) {
          this.user = userData;
          console.log('Userdata');
          console.log(userData);

          // Splitting the firstname and the last name
          const splittedName = userData.name.split(' ');

          // Calling the Login proceed function
          this.LoginWithEmail(userData.email, userData.id, 'google');
          this.showLoading = false;
        }
      });
  }

  // Call Linkedin API
  LinkedInLogin() {
    this.showLoading = true;
    // Initialize with your OAuth.io app public key
    OAuth.initialize('thXMykFVSwf6VMgIqWogNzCzd4Y');

    // Use popup for oauth
    OAuth.popup('linkedin2').then(linkedin => {
      console.log('linkedin:', linkedin);
      const token = linkedin.access_token;
      // get user details
      this.GetLinkedinUser(token);
    });
  }

  // access linkedin user
  GetLinkedinUser(token: string) {
    this.signupService.GetLinkedinUserDetails(token).subscribe(userData => {
      this.showLoading = false;
      // Response of the LINKEDIN sign in API
      if (userData !== null && userData !== undefined) {
        let email = userData["email"];
        //this.password = userData.;
        // this.fullname = userData.LocalizedFirstName + ' ' + userData.LocalizedLastName;
        this.LoginWithEmail(userData['email'], userData['id'], 'linkedin');
        // this.showLoading = true;
      }
      console.log(userData);
    });
  }

  // Call Twitter API
  TwitterLogin() {
    this.showLoading = true;
    // Initialize with your OAuth.io app public key
    OAuth.initialize('thXMykFVSwf6VMgIqWogNzCzd4Y');
    // Use popup for OAuth
    // setTimeout(function(){ 

    OAuth.popup('twitter').then(twitter => {

      // Retrieves user data from OAuth provider by using #get() and
      // OAuth provider url
      twitter.get('/1.1/account/verify_credentials.json?include_email=true').then(userData => {
        this.showLoading = false;
        console.log('self data:', userData);
        // Response of the TWITTER sign in API
        if (userData !== null && userData !== undefined) {
          // Splitting the firstname and the last name
          const splittedName = userData.name.split(' ');

          this.LoginWithEmail(userData.email, userData.id, 'twitter');

        }
      });
    });
    // }, 3000);
  }

  // Check the Email validation
  LoginWithEmail(email: string, password: string, signupMethod: string): void {
    //Perform Validation
    this.ValidateEmail(email, signupMethod, password);
  }

  //Validating the email
  ValidateEmail(emailAddress: string, signupMethod: string, password: string) {
    this.showLoading = true;
    // Calling the service method to check if the email exists
    forkJoin(this.IsEmailExists(emailAddress)).subscribe(
      results => {
        console.log("Email validation");
        console.log(results[0]);
        if (results[0] === false) {
          //Check whether the email exists in the link emails
          //if so perform link email login
          //if not redirect to not recognized
          forkJoin(this.IsLinkedEmailExists(emailAddress)).subscribe(
            results => {
              if (results[0] === false) {
                //Email is not there
                this.showLoading = false;
                this.router.navigate(['account-not-recognised', emailAddress, password, signupMethod]);

              } else {
                //Perform linked email login stage 1
                this.loginService.ValidateLinkedDetails(emailAddress, password, signupMethod).subscribe(
                  data => {

                    //Check the login validations
                    if (data.ParentEmail == "INVALIDLOGIN") {
                      console.log("Login is invalid");
                      this.invalidLogin = true;
                      this.showLoading = false;
                    } else {
                      this.signupType = "";
                      this.PerformLoginFunction(data.ParentEmail, data.ParentPassword, data.ParentSMType);
                    }

                  }
                );
              }

            }
          );

        } else {

          //Email is there
          if (signupMethod === 'EMAIL') {
            //Check whether the password is empty
            //If true -> Create password page
            //If false -> Check for the login credentials

            let passExists = true;
            this.loginService.IsPasswordExists(emailAddress).subscribe(
              passExists => {
                console.log("Password exists: " + passExists);
                //Check if the password exists
                if (passExists === true) {
                  //Perform login function
                  this.PerformLoginFunction(emailAddress, this.password, '');
                } else {
                  //Redirect to the create password
                  //Getting the sitename of the user
                  this.loginService.GetSiteName(emailAddress).subscribe(
                    sitename => {
                      console.log("Sitename: " + sitename);
                      if (sitename !== '') {
                        this.router.navigate(['account-create', { signupType: signupMethod, siteName: sitename, email: emailAddress }]);
                      }
                    }
                  );
                }
              }
            );

          } else {
            //Perform Login function
            this.PerformLoginFunction(emailAddress, password, signupMethod);
          }
        }
      }
    );
  }

  userEnteredEmail: string;
  userEnteredPersonId: number;

  //Perform login function
  PerformLoginFunction(email: string, password: string, loginType: string) {
    const loginDetails: LoginDetails = {
      LoginType: loginType,
      UserEmail: email,
      Password: password,
      ProjectType: this.IsExpertiseExchange ? "EECLIENT" : ""
    };

    // console.log(loginDetails);
    this.loginService.validateExpireDate(email, password).subscribe(
      data => {
        if (data != null) {
          if (data.IsValidAuth) {
            this.loginService.ValidateLogin(loginDetails).subscribe(
              data => {
                console.log("Validate login details");
                console.log(data);
                if (data.UserId == "INVALIDLOGIN") {
                  console.log("Login is invalid");
                  this.invalidLogin = true;
                  this.showLoading = false;
                } else {
                  console.log("Login is successfull");
                  //Setting the token
                  this.tokenDetails = data;
                  //Redirect to the dashboard
                  //Replacing http with https
                  let currentUrl = this.tokenDetails['RedirectUrl'];
                  //console.log("Redirect url");
                  //console.log(currentUrl);

                  let newurl = this.IsExpertiseExchange ? currentUrl : currentUrl.replace('http', 'https');
                  this.redirectNewUrl = newurl;
                  this.showLoading = false;

                  if (this.signupType === 'LOGIN') {
                    this.showLoading = true;
                    console.log("Email to be linked: " + this.linkingemail);
                    console.log("Password to be linked: " + this.linkingpassword);
                    console.log("Sm type to be linked: " + this.linkingsmtype);
                    console.log("Parent email: " + email);
                    console.log("Parent password: " + password);
                    console.log("Parent smtype: " + loginType);
                    //Link the account to the parent
                    this.signupService.InsertLinkedEmailDetails(email, password, loginType, this.linkingemail, this.linkingpassword, this.linkingsmtype).subscribe(
                      data => {
                        console.log("Inserting the linked email details: " + data);

                        //PerformLogin
                        this.signupType = "";

                        this.showLoading = false;

                        const userLoginDetails: UserLoginDetails = {
                          LGCompanyID: this.tokenDetails.CompanyId,
                          LGEmailAddress: this.tokenDetails.UserId,
                          LGPassword: this.tokenDetails.Password,
                          LGPersonId: this.tokenDetails.PersonId,
                          LGPersonName: this.tokenDetails.FullName,
                          LGconStr: this.tokenDetails.Token,
                          LGdomain: this.tokenDetails.Domain
                        }

                        const paramObj = btoa(JSON.stringify(userLoginDetails));
                        this.globalParamObj = paramObj;
                        // Start  
                        // Two way Authentication 

                        this.InsertAuthAuthenticationDetails();

                        // End
                        //window.location.href = this.IsExpertiseExchange ? newurl + "#/LoginPage?loginobject=" + paramObj : newurl;

                      }
                    );


                  } else {

                    const userLoginDetails: UserLoginDetails = {
                      LGCompanyID: this.tokenDetails.CompanyId,
                      LGEmailAddress: this.tokenDetails.UserId,
                      LGPassword: this.tokenDetails.Password,
                      LGPersonId: this.tokenDetails.PersonId,
                      LGPersonName: this.tokenDetails.FullName,
                      LGconStr: this.tokenDetails.Token,
                      LGdomain: this.tokenDetails.Domain
                    }

                    const paramObj = btoa(JSON.stringify(userLoginDetails));
                    this.globalParamObj = paramObj;
                    // Start  
                    // Two way Authentication

                    this.InsertAuthAuthenticationDetails();

                    // End
                    //window.location.href = this.IsExpertiseExchange ? newurl + "#/LoginPage?loginobject=" + paramObj : newurl;
                    // this.router.navigate(['dashboard']);
                  }


                }
              }
            );
          }
          else {
            this.showMymodel = true;
            this.IsOwner = data.IsOwner;
            this.MessageHeader = data.MessageHeader;
            this.Message = data.Message;
            this.AccountType = data.AccountType;
          }
        }
        else {
        }
      });

  }


  // Calling whether email exists service call
  IsEmailExists(useremail: string) {
    return this.signupService.IsEmailExists(useremail);
  }

  IsLinkedEmailExists(useremail: string) {
    return this.signupService.IsLinkedEmailExists(useremail);
  }

  InsertLinkedEmailDetails(parentEmail: string, parentPassword: string, parentSMType: string, linkedEmail: string, linkedPassword: string, linkedSMType: string) {
    return this.signupService.InsertLinkedEmailDetails(parentEmail, parentPassword, parentSMType, linkedEmail, linkedPassword, linkedSMType);
  }

  // Validating the user entered email
  ValidateEmailOnFocusout() {

    // Calling the service method to check if the email exists
    forkJoin(this.IsEmailExists(this.email)).subscribe(
      results => {
        //console.log("Email: "+this.email);
        // console.log('Email exists: ' + results[0]);

        if (results[0] === false) {
          // Display error message
          this.errorMessage = 'Email not exits';
        } else {
          this.errorMessage = '';
        }
      }
    );
  }
  btn_loginWarning_Click() {
    this.showMymodel = false;
  }

  //-------------------- Applying 2-way authentication -----------------------------//
  InsertAuthAuthenticationDetails() {
    // Get IP Details
    // this.authenticationService.GetLocationDetails().subscribe(
    //   result => {
    this.showLoading = true;
    // if (result !== null) {
    const locationDetailsObject: LocationDetails = {
      City: 'TEMP STOP',
      Region: '',
      Country: '',
      LocationCoordinates: '',
      Provider: '',
      PostalCode: '',
      TimeZone: '',
      CreatedDate: new Date(),
      IsDisable: false,
      LocationDetailsId: 0
    }

    //console.log(' Location Details :');
    //console.log(locationDetailsObject);

    if (locationDetailsObject !== null && locationDetailsObject !== undefined) {

      const authenticationDetailsObj: AuthenticationDetails = {
        UserEmail: this.tokenDetails.UserId,
        PersonId: +this.tokenDetails.PersonId,
        LocationDetails: locationDetailsObject,
        IpAddress: 'TEMP STOP',
        ThroughTwoWay: false,
        AuthenticationDetailsId: 0,
        AuthenticationStatus: "DEFAULT",
        BrowserAgent: "",
        BrowserName: "",
        LocationDetailsId: 0,
        CreatedDate: new Date(),
        IsDisable: false
      }

      // Insert Auth Authentication Details
      this.authenticationService.InsertAuthAuthenticationDetails(authenticationDetailsObj).subscribe(
        data => {
          if (data !== null) {

            this.authId = data;
            let redirectUrl = this.IsExpertiseExchange ? this.redirectNewUrl + "#/LoginPage?loginobject=" + this.globalParamObj : this.redirectNewUrl;
            const redirectUrlObj = btoa(JSON.stringify(redirectUrl));

            // Check Authentication Type
            this.authenticationService.CheckAuthAuthenticatioType(this.tokenDetails.PersonId).subscribe(
              dataAuthType => {


                this.authenticationTypes = dataAuthType;
                console.log(' Auth Types :')
                console.log(dataAuthType);

                if (this.authenticationTypes !== null) {
                  // Check Email or SMS
                  if (this.authenticationTypes.IsEmail === false && this.authenticationTypes.IsSMS === false) {
                    // Set Cookies
                    this.SetCookies();
                    this.showLoading = false;
                    // Goto Home Page
                    window.location.href = this.IsExpertiseExchange ? this.redirectNewUrl + "#/LoginPage?loginobject=" + this.globalParamObj : this.redirectNewUrl;
                  }
                  else if (this.authenticationTypes.IsEmail === true && this.authenticationTypes.IsSMS === true) {
                    // Check Authentication Details 
                    // AuthChecking = this.GetTriggeringAuth(locationDetailsObject.Region, +this.tokenDetails.PersonId);

                    this.authenticationService.GetTriggeringAuth(+this.tokenDetails.PersonId, locationDetailsObject.Region).subscribe(
                      data => {
                        if (data == true) {
                          this.SetCookies();
                          this.showLoading = false;
                          // Redirect to authenticate - email
                          this.router.navigate(['authentication-verification', { personID: this.tokenDetails.PersonId, authId: this.authId, navigateString: redirectUrlObj }]);
                        } else {
                          if (this.cookieService.get('AUTH$PERSONID') === null || this.cookieService.get('AUTH$PERSONID') === "" ||
                            this.cookieService.get('AUTH$PERSONID').toString().trim() !== this.tokenDetails.PersonId.trim()) {
                            this.SetCookies();
                            this.showLoading = false;
                            // Redirect to authenticate - email
                            this.router.navigate(['authentication-verification', { personID: this.tokenDetails.PersonId, authId: this.authId, navigateString: redirectUrlObj }]);
                          } else {
                            // Goto Home Page
                            this.showLoading = false;
                            window.location.href = this.IsExpertiseExchange ? this.redirectNewUrl + "#/LoginPage?loginobject=" + this.globalParamObj : this.redirectNewUrl;

                          }
                        }
                      }
                    );

                  }
                  else if (this.authenticationTypes.IsEmail === true && this.authenticationTypes.IsSMS === false) {
                    // authentication email - personID, type:EMAIL
                    // Check Authentication Details 
                    //AuthChecking = this.GetTriggeringAuth(locationDetailsObject.Region, +this.tokenDetails.PersonId);

                    this.authenticationService.GetTriggeringAuth(+this.tokenDetails.PersonId, locationDetailsObject.Region).subscribe(
                      data => {
                        if (data == true) {
                          this.SetCookies();
                          this.showLoading = false;
                          // Redirect to authenticate - email
                          this.router.navigate(['authenticate-email', { personID: this.tokenDetails.PersonId, type: "EMAIL", authId: this.authId, navigateString: redirectUrlObj }]);

                        } else {
                          console.log(this.cookieService.get('AUTH$PERSONID'));
                          if (this.cookieService.get('AUTH$PERSONID') === null || this.cookieService.get('AUTH$PERSONID') === "" ||
                            this.cookieService.get('AUTH$PERSONID').toString().trim() !== this.tokenDetails.PersonId.trim()) {
                            // Set Cookies
                            this.SetCookies();
                            this.showLoading = false;
                            // Redirect to authenticate - email
                            this.router.navigate(['authenticate-email', { personID: this.tokenDetails.PersonId, type: "EMAIL", authId: this.authId, navigateString: redirectUrlObj }]);

                          }
                          else {
                            this.showLoading = false;
                            // Goto Home Page
                            window.location.href = this.IsExpertiseExchange ? this.redirectNewUrl + "#/LoginPage?loginobject=" + this.globalParamObj : this.redirectNewUrl;

                          }

                        }
                      }
                    );

                  }
                  else if (this.authenticationTypes.IsEmail === false && this.authenticationTypes.IsSMS === true) {
                    // authentication code - personID, type:SMS

                    this.authenticationService.GetTriggeringAuth(+this.tokenDetails.PersonId, locationDetailsObject.Region).subscribe(
                      data => {
                        if (data == true) {
                          this.SetCookies();
                          this.showLoading = false;
                          // Redirect to authenticate - email
                          this.router.navigate(['authenticate-code', { personID: this.tokenDetails.PersonId, type: "SMS" }]);
                        } else {
                          if (this.cookieService.get('AUTH$PERSONID') === null || this.cookieService.get('AUTH$PERSONID') === "" ||
                            this.cookieService.get('AUTH$PERSONID').toString().trim() !== this.tokenDetails.PersonId.trim()) {
                            // Set Cookies
                            this.SetCookies();
                            this.showLoading = false;
                            // Redirect to authenticate - email
                            this.router.navigate(['authenticate-code', { personID: this.tokenDetails.PersonId, type: "SMS" }]);
                          } else {
                            // Goto Home Page
                            this.showLoading = false;
                            window.location.href = this.IsExpertiseExchange ? this.redirectNewUrl + "#/LoginPage?loginobject=" + this.globalParamObj : this.redirectNewUrl;
                          }
                        }
                      }
                    );


                  }
                  else {
                    // Set Cookies
                    // this.SetCookies();
                    // if (this.cookieValue !== '') {
                    // Goto Home Page
                    this.showLoading = false;
                    window.location.href = this.IsExpertiseExchange ? this.redirectNewUrl + "#/LoginPage?loginobject=" + this.globalParamObj : this.redirectNewUrl;
                    // }
                  }

                } else {
                  // Set Cookies
                  // this.SetCookies();
                  // if (this.cookieValue !== '') {
                  // Goto Home Page
                  this.showLoading = false;
                  window.location.href = this.IsExpertiseExchange ? this.redirectNewUrl + "#/LoginPage?loginobject=" + this.globalParamObj : this.redirectNewUrl;
                  // }
                }
              }
            );
          }
          else {
            // Display Error Message;
            this.showLoading = false;
            console.log('Saving Fail: AuthAuthenticationDetails');
          }
        }
      );

    }

    // }else{
    this.showLoading = false;
    // }

    //   }
    // );
  }

  // Set cookies
  SetCookies() {
    // Set cookies
    this.cookieService.set('AUTH$PERSONID', this.tokenDetails.PersonId);
  }

  //-------------------- End of Applying 2-way authentication  -----------------------------//


}
