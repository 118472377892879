import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { LoginComponent } from './components/login/login.component';
import { AccountCreateComponent } from './components/account-create/account-create.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { AccountNotRecognisedComponent } from './components/account-not-recognised/account-not-recognised.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EEAutoSiteCreationComponent } from './components/eeauto-site-creation/eeauto-site-creation.component';
import { EecontactListComponent } from './components/eecontact-list/eecontact-list.component';
import { AuthenticateEmailComponent } from './components/authenticate-email/authenticate-email.component';
import { AuthenticateCodeComponent } from './components/authenticate-code/authenticate-code.component';
import { AuthenticationVerificationComponent } from './components/authentication-verification/authentication-verification.component';


const routes: Routes = [
  { 
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard', component: DashboardComponent
  },
  {
    path: 'signup', component: SignUpComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'EESubscription', component: EEAutoSiteCreationComponent
  },
  {
    path: 'EESubscription/:id', component: EEAutoSiteCreationComponent
  },
  {
    path: 'login/:signupType/:linkingemail/:linkingpassword/:linkingsmtype', component: LoginComponent
  },
  {
    path: 'login/:email/:password', component: LoginComponent
  },
  {
    path: 'login/:firstName/:lastName/:email', component: LoginComponent
  },
  {
    path: 'login/:email/:password/:signupType/:loginwithemail', component: LoginComponent
  },
  {
    path: 'account-create', component: AccountCreateComponent
  },
  {
    path: 'account-create/:signupType/:firstName/:lastName/:email/:smid', component: AccountCreateComponent
  },
  {
    path: 'account-create/:signupType', component: AccountCreateComponent
  },
  {
    path: 'account-create/:signupType/:siteName/:userID', component: AccountCreateComponent
  },
  {
    path: 'account-create/:signupType/:siteName/:email', component: AccountCreateComponent
  },
  {
    path: 'email-verification', component: EmailVerificationComponent,
  }
  ,
  {
    path: 'email-verification/:email/:password/:emailObject', component: EmailVerificationComponent
  },
  {
    path: 'account-not-recognised/:email/:password/:smtype', component: AccountNotRecognisedComponent
  },
  {
    path: 'account-not-recognised', component: AccountNotRecognisedComponent
  },
  {
    path: 'eecontact-list', component: EecontactListComponent
  },
  {
    path: 'authenticate-email', component: AuthenticateEmailComponent
  },
  {
    path: 'authenticate-email/:personID/:type/:authId/:navigateString', component: AuthenticateEmailComponent
  },
  {
    path: 'authenticate-code', component: AuthenticateCodeComponent
  },
  {
    path: 'authenticate-code/:personID/:type/:valueID:/valueString/:authId/:navigateString', component: AuthenticateCodeComponent
  },
  {
    path: 'authentication-verification', component: AuthenticationVerificationComponent
  },
  {
    path: 'authentication-verification/:personID/:authId/:navigateString', component: AuthenticationVerificationComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
