import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { SignupService } from 'src/app/service/signup.service';
import { LoginService } from 'src/app/service/login.service';
import { LoginDetails } from 'src/app/core/loginDetails';
import { UserLoginDetails } from 'src/app/core/userLoginDetails';
import { TokenDetails } from 'src/app/core/tokenDetails';

@Component({
  selector: 'app-eeauto-site-creation',
  templateUrl: './eeauto-site-creation.component.html',
  styleUrls: ['./eeauto-site-creation.component.css']
})
export class EEAutoSiteCreationComponent implements OnInit {
  
  SiteMessage:string="";
  tokenDetails: TokenDetails;
  key:string="";
  showAnimation:boolean=false;
  constructor(private route: ActivatedRoute, private signupService: SignupService, private loginService: LoginService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
    if (params.get('id')) {
      this.key = params.get('id');
    }
    if(this.key!=""){
      this.SiteMessage ="Please wait for a while, Your site is creating...";
      this.showAnimation = true;
      
      this.signupService.EESubscriptioncreate(this.key).subscribe(
        results=>{
        this.loginService.ValidateLinkedDetails(results.UserEmail,results.Password,'EMAIL').subscribe(
          data=>{

            //Check the login validations
            if(data.ParentEmail == "INVALIDLOGIN"){
              console.log("Login is invalid");
            }else{
              const loginDetails: LoginDetails = {
                LoginType: 'EMAIL',
                UserEmail: results.UserEmail,
                Password: results.Password,
                ProjectType: ""
              };
            
              console.log(loginDetails);
            
              this.loginService.ValidateLogin(loginDetails).subscribe(
                data => {
                  console.log("Validate login details");
                  console.log(data);
                  if (data.UserId == "INVALIDLOGIN") {
                    console.log("Login is invalid");
                  } else {
                    console.log("Login is successfull");
                    //Setting the token
                    this.tokenDetails = data;
                    //Redirect to the dashboard
                    //Replacing http with https
                    let currentUrl = this.tokenDetails['RedirectUrl'];
                    //console.log("Redirect url");
                    //console.log(currentUrl);
                    let newurl = currentUrl.replace('http', 'https');
                    
                    
                    window.location.href = newurl;
                    this.showAnimation = false;
                      // this.router.navigate(['dashboard']);
                    }
                  }
              );
            }
          }
        );
      });
    }
    else{
      this.SiteMessage ="Your link is expired";
    }
  });
}

  

}
