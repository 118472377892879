// Importing the libraries
import { Component, OnInit } from '@angular/core';
import { AuthService, SocialUser, GoogleLoginProvider, FacebookLoginProvider, LinkedinLoginProvider } from 'ng4-social-login';
import { Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { Location } from '@angular/common';

// Importing the service classes
import { SignupService } from 'src/app/service/signup.service';

//importing the common model for the config changes
import * as globals from 'src/app/core/common';

//importing the enum model
import {projects} from 'src/app/enum/projects';

declare var OAuth: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
  providers: [SignupService]
})
export class SignUpComponent implements OnInit {
  //config variables
  usingProject:projects;

  IsExpertiseExchange:boolean = false; 

  // Global variables
  title = '14 days free trial';
  subtitle = 'Select your preferred sign up method';
  public user: any = SocialUser;
  fullname: string;
  alreadyExists:boolean = false;
  IsacceptTermsAndPrivacyPolicy:boolean = false;
  // Check Email Address
  emailExistsMessage: string;

  showLoading:boolean = false;

  // Hide message
  hiddenEmailExistsMessage: boolean;

  isShowPopupIsacceptTermsAndPrivacyPolicyWarning: boolean = false;
  popupIsacceptTermsAndPrivacyPolicyWarningTitle: string = '';
  
  // Constructor of the class
  constructor(private socialAuthService: AuthService,private readonly LocationObj: Location, private router: Router, private signupService: SignupService) { }

  // NgOnInit method
  ngOnInit() {
    this.usingProject = globals.USINGPROJECT;
    this.IsExpertiseExchange= this.usingProject == projects.ExpertiseExchange;

    this.LocationObj.replaceState("");
    
    this.fullname = '';
    this.emailExistsMessage = "";
    this.hiddenEmailExistsMessage = true;
    this.alreadyExists = false;
  }

  // ---------------------------------- Button onclick functions -----------------------------//

  // Call Facebook API
  FacebookLogin() {
    this.showLoading= true;
    this.alreadyExists = false;
    this.hiddenEmailExistsMessage = true;
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
      (userData) => {
        this.showLoading = false;
        if (userData !== null && userData !== undefined) {
          this.user = userData;
          // console.log(userData.email,userData.name);
          // this.signUpWithEmail(userData.email, userData.name);
           // Splitting the firstname and the last name
           const splittedName = userData.name.split(' ');

           // Calling the signup proceed function
           this.SignUpWithEmail(userData.email, splittedName[0].toString(), splittedName[1].toString(), 'FACEBOOK', userData.id);
        } else {
          console.log('facebookLogin Error');
        }
      });
  }

  // Call Google API
  GoogleLogin() {
    this.showLoading= true;
    this.alreadyExists = false;
    this.hiddenEmailExistsMessage = true;
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
      (userData) => {
        this.showLoading= false;
        // Response of GOOGLE sign in API
        if (userData !== null && userData !== undefined) {
          this.user = userData;
          console.log('Userdata');
          console.log(userData);

          // Splitting the firstname and the last name
          const splittedName = userData.name.split(' ');

          // Calling the signup proceed function
          this.SignUpWithEmail(userData.email, splittedName[0].toString(), splittedName[1].toString(), 'GOOGLE', userData.id);
        }
      });
  }

  // Call Linkedin API
  LinkedInLogin() {
    this.showLoading= true;
    this.alreadyExists = false;
    this.hiddenEmailExistsMessage = true;
    // Initialize with your OAuth.io app public key
    OAuth.initialize('thXMykFVSwf6VMgIqWogNzCzd4Y');
    // Use popup for oauth
    OAuth.popup('linkedin2').then(linkedin => {
      console.log('linkedin:', linkedin);
      const token = linkedin.access_token;
      // get user details
      this.GetLinkedinUser(token);
    });
  }

  // access linkedin user
  GetLinkedinUser(token: string) {
    this.signupService.GetLinkedinUserDetails(token).subscribe(userData => {
      this.showLoading= false;
      // Response of the LINKEDIN sign in API
      if (userData !== null && userData !== undefined) {
        // this.fullname = userData.LocalizedFirstName + ' ' + userData.LocalizedLastName;
        this.SignUpWithEmail(userData['email'], userData['localizedFirstName'], userData['localizedLastName'], 'LINKEDIN',userData['id']);
      }
      console.log(userData);
    });
  }



  // Call Twitter API
  TwitterLogin() {
    this.showLoading= true;
    this.alreadyExists = false;
    this.hiddenEmailExistsMessage = true;
    // Initialize with your OAuth.io app public key
    OAuth.initialize('thXMykFVSwf6VMgIqWogNzCzd4Y');
    // Use popup for OAuth
    OAuth.popup('twitter').then(twitter => {
      // Retrieves user data from OAuth provider by using #get() and
      // OAuth provider url 
      twitter.get('/1.1/account/verify_credentials.json?include_email=true').then(userData => {
        this.showLoading= false;
        console.log('self data:', userData);
        // Response of the TWITTER sign in API
        if (userData !== null && userData !== undefined) {
          // Splitting the firstname and the last name
          const splittedName = userData.name.split(' ');

          this.SignUpWithEmail(userData.email, splittedName[0].toString(), splittedName[1].toString(), 'TWITTER',userData.id);
        }
      });
    });
  }

  // Call email
  EmailLogin() {
    this.alreadyExists = false;
    this.hiddenEmailExistsMessage = true;
    this.SignUpWithEmail('', '', '', 'EMAIL','');
  }

  // ---------------------------------- End of button onclick functions -----------------------------//



  // ----------------------------- Shared methods ---------------------------------//

  // Check if the email exists
  ValidateEmail(emailAddress: string,signupMethod:string,firstName:string,lastName:string, smid:string) {
    this.hiddenEmailExistsMessage = true;
    // Calling the service method to check if the email exists
    forkJoin(this.IsEmailExists(emailAddress), this.IsLinkedEmailExists(emailAddress)).subscribe(
      results => {
        if (results[0] === false && results[1] === false ) {
          //Redirecting to site creation page
          this.router.navigate(['account-create', signupMethod, firstName, lastName, emailAddress,smid]);
        } else {
          console.log('Email already exists');
          this.alreadyExists = true;
          this.hiddenEmailExistsMessage = false;
          this.emailExistsMessage = 'Email already exists';
        }
      }
    );
  }

  // Check the signup type and redirect to the sitecreation page
  SignUpWithEmail(email: string, firstName: string, lastName: string, signupMethod: string, smid:string): void {
    this.hiddenEmailExistsMessage = true;
    this.alreadyExists = false;
    if (email !== undefined && firstName !== undefined && lastName !== undefined && signupMethod !== 'EMAIL') {
      this.ValidateEmail(email,signupMethod,firstName,lastName, smid);
    } else {
      this.router.navigate(['account-create', signupMethod]);
    }
  }

  AlreadyLogin(){
    this.router.navigate(['login']);
  }

  popupIsacceptTermsAndPrivacyPolicyWarning(){
    this.isShowPopupIsacceptTermsAndPrivacyPolicyWarning = !this.IsacceptTermsAndPrivacyPolicy;
  }

  closePopupIsacceptTermsAndPrivacyPolicyWarning(event:Event){
    this.isShowPopupIsacceptTermsAndPrivacyPolicyWarning =false;
  }

  acceptTermsAndPrivacyPolicy(){
    this.IsacceptTermsAndPrivacyPolicy = !this.IsacceptTermsAndPrivacyPolicy;
  }
  // ----------------------- End of Shared methods --------------------------------//

  // ----------------------------- Service calls ---------------------------------//

  // Calling whether email exists service call
  /* tslint:disable:no-string-literal */
  IsEmailExists(useremail: string) {
    return this.signupService.IsEmailExists(useremail);
  }

  IsLinkedEmailExists(useremail: string) {
    return this.signupService.IsLinkedEmailExists(useremail);
  }
  // ----------------------- End of Service calls --------------------------------//
}