//Importing the libraries
import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

//Importing core classes
import * as globals from '../core/common';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class EesubscriptionService {

  //The webapi controller urls
  private setUploadContactDetailsUrl = globals.signupServerBase + '/api/EESubscription/UploadContactDetails';
  private setSendEmailsUrl = globals.signupServerBase + '/api/EESubscription/SendAutoEmail';


  //Constructor of the class
  constructor(private _http: Http) { }

  UploadExcel(formData: FormData){
    let headers = new HttpHeaders();  
  
    headers.append('Content-Type', 'multipart/form-data');  
    headers.append('Accept', 'application/json'); 
    
    const httpOptions = { headers: headers };

    return this._http.post(this.setUploadContactDetailsUrl, formData)
      .map((response: Response) => {})
      .catch(this.handleError);
  }


  SendEmails(){
    return this._http.get(this.setSendEmailsUrl)
    .map((response: Response) => {})
    .catch(this.handleError);
  }







  //The function of handling the error
  private handleError(error: Response) {
    console.log("Error in contact service");
    console.log(error);
    return Observable.throw(error.json().error || 'Server error');
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }
}
