
//Importing the libraries
import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

//Importing core classes
import * as globals from '../core/common';
import { LoginDetails } from '../core/loginDetails';
import { TokenDetails } from '../core/tokenDetails';
import { ParentInfo } from '../core/parentInfo';
// import { Http, Response } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  //The webapi controller urls
  private getIsPasswordExistsUrl = globals.signupServerBase + '/api/SignUpApi/IsPasswordExists';
  private getSitenameUrl = globals.signupServerBase + '/api/SignUpApi/GetSiteName';
  private setAddEmailMethodUrl = globals.signupServerBase + '/api/SignUpApi/AddEmailMethod';
  private setLoginValidateMethodUrl = globals.signupServerBase + '/api/SignUpApi/ValidateLoginDetails';
  private getValidateLinkedDetails = globals.signupServerBase + '/api/SignUpApi/ValidateLinkedEmail';
  private validateExpireDateNewUrl = globals.signupServerBase + '/api/SignUpApi/ValidateExpireDateNew';

  //Global variables
  headers: any;

  //Constructor of the class
  constructor(private _http: Http) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  ValidateLinkedDetails(linkedEmail: string, linkedPassword: string, linkedSMType: string): Observable<ParentInfo>{
    let myparams = new URLSearchParams();
    myparams.append("linkedEmail", linkedEmail);
    myparams.append("linkedPassword", linkedPassword);
    myparams.append("linkedSMType", linkedSMType);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.getValidateLinkedDetails, options)
      .map((response: Response) => <ParentInfo>response.json())
      .catch(this.handleError);
  }

  //API call to login validate
  ValidateLogin(loginDetails:LoginDetails): Observable<TokenDetails>{
    let myparams = new URLSearchParams();
    myparams.append("email", loginDetails.UserEmail);
    myparams.append("password", loginDetails.Password);
    myparams.append("smtype", loginDetails.LoginType);
    myparams.append("projectType", loginDetails.ProjectType);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.setLoginValidateMethodUrl, options)
      .map((response: Response) => <TokenDetails>response.json())
      .catch(this.handleError);
  }

  //API call to set the Add email method
  SetEmailMethod(email: string, password: string): Observable<boolean> {
    let myparams = new URLSearchParams();
    myparams.append("email", email);
    myparams.append("password", password);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.setAddEmailMethodUrl, options)
      .map((response: Response) => <boolean>response.json())
      .catch(this.handleError);
  }

  //API calll to get the sitename
  GetSiteName(userEmail: string): Observable<string> {
    let myparams = new URLSearchParams();
    myparams.append("userEmail", userEmail);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.getSitenameUrl, options)
      .map((response: Response) => <string>response.json())
      .catch(this.handleError);
  }


  
  //API call to check if the sitename already exists
  validateExpireDate(email: string, password: string): Observable<any> {
    let myparams = new URLSearchParams();
    myparams.append("email", email);
    myparams.append("password", password);
    let options = new RequestOptions({ params: myparams });


    return this._http.get(this.validateExpireDateNewUrl, options)
      .map((response: Response) => <any>response.json())
      .catch(this.handleError);
  }


  //API call to check if the userEmail already exists
  IsPasswordExists(userEmail: string){
    let myparams = new URLSearchParams();
    myparams.append("userEmail", userEmail);

    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.getIsPasswordExistsUrl, options)
      .map((response: Response) => <boolean>response.json())
      .catch(this.handleError);
  }

  //Login Method



  //The function of handling the error
  private handleError(error: Response) {
    console.log("Error in contact service");
    console.log(error);
    return Observable.throw(error.json().error || 'Server error');
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }
}

