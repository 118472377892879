import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { UserEmail } from 'src/app/core/userEmail';

@Component({
  selector: 'app-authenticate-email',
  templateUrl: './authenticate-email.component.html',
  styleUrls: ['./authenticate-email.component.css'],
  providers: [AuthenticationService]
})
export class AuthenticateEmailComponent implements OnInit {

  //Variables to store the selected email
  selectedEmail: UserEmail;
  //Variable to hold the emails
  emailList: any[] = [
    { UserEmail:0, Email: "john@smith.com" },
    { UserEmail:1, Email: "rashmalat@gmail.com" }
  ]
  personID: number;
  userEmailList: UserEmail[] = [];
  authId:number;
  navigateString:string;

  showLoading:boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {

    this.showLoading = true;

    //Retereive the params from routing
    this.route.paramMap.subscribe(params => {

      if (params.get('personID')) {
        this.personID = +params.get('personID');
      }
      if (params.get('authId')) {
        this.authId = +params.get('authId');
      }
      if (params.get('navigateString')) {
        this.navigateString = params.get('navigateString');
      }
    });

    debugger;
    // Take the user emails
    this.authenticationService.GetUserEmails(this.personID).subscribe(
      data => {
        this.userEmailList = data;

        // Check the no of emails
        if (this.userEmailList.length == 1) {
          // Redirect to authentication code
          this.showLoading = false;
          this.router.navigate(['authenticate-code', { personID: this.personID, type: "EMAIL", valueID: this.userEmailList[0].EmailId, valueString:this.userEmailList[0].Email ,authId:this.authId,navigateString:this.navigateString}]);
        } else {
          this.emailList = [];
          // Populate the dropdown
          for (let i = 0; i < this.userEmailList.length; i++){
            this.emailList.push(this.userEmailList[i]);            
          }
          this.selectedEmail = this.emailList[0];
        }
        this.showLoading = false;

      }
    );

  }

  //On click function of email me button
  sendGenerateCode(){    
    this.router.navigate(['authenticate-code', { personID: this.personID, type: "EMAIL", valueID: this.selectedEmail.EmailId, valueString:this.selectedEmail.Email ,authId:this.authId,navigateString:this.navigateString}]);
  }

}
