import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

//Importing core classes
import * as globals from '../core/common';
import { LocationDetails } from '../core/locationDetails';
import { AuthenticationDetails } from '../core/authenticationDetails';
import { AuthenticationTypes } from '../core/authenticationTypes';
import { UserEmail } from '../core/userEmail';
import { UserMobile } from '../core/userMobile';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  // The webapi controller urls
  private SaveAuthAuthenticationDetailsUrl = globals.signupServerBase + '/api/SignUpApi/InsertAuthenticationDetials';
  private AuthTypesUrl = globals.signupServerBase + '/api/SignUpApi/GetAuthenticationTypes';
  private GetUserEmailsUrl = globals.signupServerBase + '/api/SignUpApi/GetUserEmails';
  private GenerateCodeUrl = globals.signupServerBase + '/api/SignUpApi/GenerateCode';
  private ValidateCodeUrl = globals.signupServerBase + '/api/SignUpApi/ValidateCode';
  private GetTriggeringAuthUrl = globals.signupServerBase + '/api/SignUpApi/TriggeringAuthentication';
  private GetUserMobilesUrl = globals.signupServerBase + '/api/SignUpApi/GetUserMobiles';
  
  // Global variables
  headers: any;

  constructor(private http: Http) {
    this.headers = new Headers();
  }

  //Geting the user mobiles
  GetUserMobiles(personID:number){
    let urlSearchParams = new URLSearchParams();    
    urlSearchParams.append('personID', personID.toString());
    let options = new RequestOptions({ params: urlSearchParams });
    
    return this.http.get(this.GetUserMobilesUrl, options)
    .map((response: Response) => <UserMobile[]>response.json())
    .catch(this.handleError); 
  }

  //Getting the triggering auth
  GetTriggeringAuth(personID:number,ipRegion:string){ 
    let urlSearchParams = new URLSearchParams();    
    urlSearchParams.append('personID', personID.toString());
    urlSearchParams.append('ipRegion', ipRegion.toString());
    let options = new RequestOptions({ params: urlSearchParams });

    return this.http.get(this.GetTriggeringAuthUrl, options)
    .map((response: Response) => <boolean>response.json())
    .catch(this.handleError);
  }

  //ValidateCode - Email
  ValidateCode( generatedCode:string,  personID:number, userEmailID:number){
    let urlSearchParams = new URLSearchParams();    
    urlSearchParams.append('generatedCode', generatedCode.toString());
    urlSearchParams.append('personID', personID.toString());
    urlSearchParams.append('userEmailID', userEmailID.toString());
    let options = new RequestOptions({ params: urlSearchParams });

    return this.http.get(this.ValidateCodeUrl, options)
    .map((response: Response) => <string>response.json())
    .catch(this.handleError);
  }

  //Generate code - Email
  GenerateEmailCode( authID:number,  duration:number, userEmailID:number){
    let urlSearchParams = new URLSearchParams();    
    urlSearchParams.append('authID', authID.toString());
    urlSearchParams.append('duration', duration.toString());
    urlSearchParams.append('userEmailID', userEmailID.toString());
    let options = new RequestOptions({ params: urlSearchParams });

    return this.http.get(this.GenerateCodeUrl, options)
    .map((response: Response) => <boolean>response.json())
    .catch(this.handleError);
  }

  //Geting the user emails
  GetUserEmails(personID:number){
    let urlSearchParams = new URLSearchParams();    
    urlSearchParams.append('personID', personID.toString());
    let options = new RequestOptions({ params: urlSearchParams });
    
    return this.http.get(this.GetUserEmailsUrl, options)
    .map((response: Response) => <UserEmail[]>response.json())
    .catch(this.handleError); 
  }

  // Get Location Details
  GetLocationDetails() {
    return this.http.get('http://ip-api.com/json')
      .map((response: Response) => <any>response.json())
      .catch(this.handleError);
  }

  // Insert Auth Authentication Details 
  InsertAuthAuthenticationDetails(authenticationDetails: AuthenticationDetails) {
    // let urlSearchParams = new URLSearchParams();    
    // urlSearchParams.append('authenticationDetails', authenticationDetails.toString());
    // let body = urlSearchParams.toString();    
    
    // return this.http.post(this.SaveAuthAuthenticationDetailsUrl, {params:authenticationDetails})
    // .map((response: Response) => <any>response.json())
    // .catch(this.handleError);  
    
    let myparams = new URLSearchParams();

    // Location
    myparams.append("City", authenticationDetails.LocationDetails.City);
    myparams.append("Region", authenticationDetails.LocationDetails.Region);
    myparams.append("Country", authenticationDetails.LocationDetails.Country);
    myparams.append("LocationCoordinates", authenticationDetails.LocationDetails.LocationCoordinates);
    myparams.append("Provider", authenticationDetails.LocationDetails.Provider);
    myparams.append("PostalCode", authenticationDetails.LocationDetails.PostalCode);
    myparams.append("TimeZone", authenticationDetails.LocationDetails.TimeZone);
    myparams.append("CreatedDateLocation", "05/05/2019");
    myparams.append("IsDisableLocation", authenticationDetails.LocationDetails.IsDisable.toString());
    myparams.append("LocationDetailsId", authenticationDetails.LocationDetails.LocationDetailsId.toString());

    // Auth Details
    myparams.append("UserEmail", authenticationDetails.UserEmail);
    myparams.append("PersonId", authenticationDetails.PersonId.toString());    
    myparams.append("IpAddress", authenticationDetails.IpAddress);
    myparams.append("ThroughTwoWay", authenticationDetails.ThroughTwoWay.toString());
    myparams.append("AuthenticationDetailsId", authenticationDetails.AuthenticationDetailsId.toString());
    myparams.append("AuthenticationStatus", authenticationDetails.AuthenticationStatus);
    myparams.append("BrowserAgent", authenticationDetails.BrowserAgent);
    myparams.append("BrowserName", authenticationDetails.BrowserName);
    myparams.append("LocationDetailsId", authenticationDetails.LocationDetailsId.toString());
    myparams.append("CreatedDate", "05/05/2019");
    myparams.append("IsDisable", authenticationDetails.IsDisable.toString());


    let options = new RequestOptions({ params: myparams });

    return this.http.get(this.SaveAuthAuthenticationDetailsUrl, options)
      .map((response: Response) => <any>response.json())
      .catch(this.handleError);


  }

  // Get AuthAuthenticatio Type
  CheckAuthAuthenticatioType(PersonId: string) {
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('personID', PersonId);
   
    let options = new RequestOptions({ params: urlSearchParams });

    return this.http.get(this.AuthTypesUrl, options)
      .map((response: Response) => <AuthenticationTypes>response.json())
      .catch(this.handleError);
  }



  // The function of handling the error
  private handleError(error: Response) {    
    console.log(error);
    return Observable.throw(error.json().error || 'Server error');
  }

}
