import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { IfStmt, analyzeAndValidateNgModules } from '@angular/compiler';
import { LocationDetails } from 'src/app/core/locationDetails';
import { AuthenticationDetails } from 'src/app/core/authenticationDetails';
import { Observable, timer, NEVER, BehaviorSubject, fromEvent, of } from 'rxjs';
import { map, tap, takeWhile, share, startWith, switchMap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-authenticate-code',
  templateUrl: './authenticate-code.component.html',
  styleUrls: ['./authenticate-code.component.css'],
  providers: [AuthenticationService]
})
export class AuthenticateCodeComponent implements OnInit {

  //Variables to identify the types
  authenticationTypeEmail: boolean = true;
  authenticationTypeSMS: boolean = false;
  //Variables for error message
  errorStateInvalidCode: boolean = false;
  errorStateExpiredCode: boolean = false;
  //Variables to display the popup messages
  displayResentCodeMessage: boolean = false;




  //Passing params
  personID: number;
  type: string;
  valueID: number;
  valueString: string;
  authId: number;
  //in mins
  duration: number = 300;
  //Code variables
  code1: string;
  code2: string;
  code3: string;
  code4: string;
  code5: string;
  code6: string;

  navigateString: string;

  minutesVal: any = 0;
  secondsVal: any = 0;

  disableCountinueBtn: boolean = true;
  enableResendCode: boolean = false;

  countDownValue: number = 5;

  showLoading:boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) { }


  ngOnInit() {


    this.showLoading = true;

    //Retereive the params from routing
    this.route.paramMap.subscribe(params => {

      if (params.get('personID')) {
        this.personID = +params.get('personID');
      }

      if (params.get('type')) {
        this.type = params.get('type');
      }

      if (params.get('valueID')) {
        this.valueID = +params.get('valueID');
      }

      if (params.get('valueString')) {
        this.valueString = params.get('valueString');
      }

      if (params.get('authId')) {
        this.authId = +params.get('authId');
      }

      if (params.get('navigateString')) {
        this.navigateString = params.get('navigateString');
      }

    });

    if (this.type == "EMAIL") {
      //Generate Code
      this.authenticationService.GenerateEmailCode(this.authId, this.duration, this.valueID).subscribe(
        data => {
          this.startCountDown();
          this.showLoading = false;
        }
      );

      //Setting the type
      this.authenticationTypeEmail = true;
      this.authenticationTypeSMS = false;
    }
  }

  //Start countdown
  startCountDown() {
    this.countDownValue = 5;
    this.enableResendCode = false;
    //--------------------- Timer codes -------------------------------//

    const toggle$ = new BehaviorSubject(true);

    const K = 1000;
    const INTERVAL = K;
    const MINUTES = this.countDownValue;
    const TIME = MINUTES * K * 60;

    let current: number;
    let time = TIME;

    const toMinutesDisplay = (ms: number) => Math.floor(ms / K / 60);
    const toSecondsDisplay = (ms: number) => Math.floor(ms / K) % 60;

    const toSecondsDisplayString = (ms: number) => {
      const seconds = toSecondsDisplay(ms);
      return seconds < 10 ? `0${seconds}` : seconds.toString();
    };

    const currentSeconds = () => time / INTERVAL;
    const toMs = (t: number) => t * INTERVAL
    const toRemainingSeconds = (t: number) => currentSeconds() - t;

    const remainingSeconds$ = toggle$.pipe(
      switchMap((running: boolean) => (running ? timer(0, INTERVAL) : NEVER)),
      map(toRemainingSeconds),
      takeWhile(t => t >= 0),
    );

    const ms$ = remainingSeconds$.pipe(
      map(toMs),
      tap(t => current = t)
    );

    const minutes$ = ms$.pipe(
      map(toMinutesDisplay),
      map(s => s.toString()),
      startWith(toMinutesDisplay(time).toString())
    );

    const seconds$ = ms$.pipe(
      map(toSecondsDisplayString),
      startWith(toSecondsDisplayString(time).toString())
    );

    // update DOM
    const minutesElement = document.querySelector('.minutes');
    const secondsElement = document.querySelector('.seconds');
    const toggleElement = document.querySelector('.timer');


    this.updateDom(minutes$, "MIN");
    this.updateDom(seconds$, "SEC");


    remainingSeconds$.subscribe(data => {
      if (this.minutesVal == 0 && this.secondsVal == 0) {
        this.timeUpFunction();
      }

    });



    //---------------End of timer---------------------
  }

  //Updae the timer
  updateDom(source$: Observable<string>, element: string) {

    if (element == "MIN") {
      source$.subscribe((value) => this.minutesVal = +value);
    } else {
      source$.subscribe((value) => this.secondsVal = +value);
    }

  }

  //When the timer is over
  timeUpFunction() {
    this.enableResendCode = true;
    this.errorStateExpiredCode = true;
  }


  //On click function for continue btn
  onClickContinue() {
    this.showLoading = true;
    let codeString = this.code1 + "" + this.code2 + "" + this.code3 + "" + this.code4 + "" + this.code5 + "" + this.code6;

    let resultType = "";

    this.authenticationService.ValidateCode(codeString, this.personID, this.valueID).subscribe(
      data => {
        resultType = data;

        if (resultType === 'VALID') {
          //Redirect to the home page
          this.saveRecord('VALID');
          let redirectUrlString = <string>JSON.parse(atob(this.navigateString));
          this.showLoading = false;
          window.location.href = redirectUrlString;
        } else if (resultType === 'EXPIERED') {
          this.errorStateInvalidCode = false;
          this.errorStateExpiredCode = true;
          this.displayResentCodeMessage = false;
          this.showLoading = false;
          //writing to the authentication details
          this.saveRecord('EXPIERED');
        } else if (resultType === 'INVALID') {
          this.errorStateInvalidCode = true;
          this.errorStateExpiredCode = false;
          this.displayResentCodeMessage = false;
          this.showLoading = false;
          this.saveRecord('INVALID');
        }

      }
    );

  }

  //Clos the popup
  closePopError() {
    this.displayResentCodeMessage = false;
    this.errorStateExpiredCode = false;

  }


  resendOnClickFunction() {
    this.showLoading = true;
    this.displayResentCodeMessage = true;

    this.authenticationService.GenerateEmailCode(this.authId, this.duration, this.valueID).subscribe(
      data => {
        this.code1 = null;
        this.code2 = null;
        this.code3 = null;
        this.code4 = null;
        this.code5 = null;
        this.code6 = null;

        this.startCountDown();
        this.showLoading = false;
      }
    );

  }



  //Code paste onchange function
  onChangeCodeFunction() {
    if (this.code1 != null && this.code2 != null && this.code3 != null && this.code4 != null && this.code5 != null && this.code6 != null &&
      this.code1 != "" && this.code2 != "" && this.code3 != "" && this.code4 != "" && this.code5 != "" && this.code6 != "") {
      this.disableCountinueBtn = false;
    } else {
      this.disableCountinueBtn = true;
    }
  }

  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');

    pastedText = pastedText.replace('-',"");


    this.code1 = pastedText.charAt(0);
    this.code2 = pastedText.charAt(1);
    this.code3 = pastedText.charAt(2);
    this.code4 = pastedText.charAt(3);
    this.code5 = pastedText.charAt(4);
    this.code6 = pastedText.charAt(5);

  }




  saveRecord(status: string) {
    this.authenticationService.GetLocationDetails().subscribe(
      result => {
        if (result !== null) {
          const locationDetailsObject: LocationDetails = {
            City: result['city'],
            Region: result['region'],
            Country: result['country'],
            LocationCoordinates: result['lat'] + ',' + result['lat'],
            Provider: result['isp'],
            PostalCode: result['zip'],
            TimeZone: result['timezone'],
            CreatedDate: new Date(),
            IsDisable: false,
            LocationDetailsId: 0
          }

          if (locationDetailsObject !== null && locationDetailsObject !== undefined) {
            const authenticationDetailsObj: AuthenticationDetails = {
              UserEmail: this.valueString,
              PersonId: this.personID,
              LocationDetails: locationDetailsObject,
              IpAddress: result['query'],
              ThroughTwoWay: false,
              AuthenticationDetailsId: 0,
              AuthenticationStatus: status,
              BrowserAgent: "",
              BrowserName: "",
              LocationDetailsId: 0,
              CreatedDate: new Date(),
              IsDisable: false
            }

            this.authenticationService.InsertAuthAuthenticationDetails(authenticationDetailsObj).subscribe(
              data => {
                console.log(data);
              }
            );


          }


        }
      }
    );
  }





}
