// Importing libraries
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

//importing the common model for the config changes
import * as globals from 'src/app/core/common';

//importing the enum model
import {projects} from 'src/app/enum/projects';

@Component({
  selector: 'app-account-not-recognised',
  templateUrl: './account-not-recognised.component.html',
  styleUrls: ['./account-not-recognised.component.css']
})
export class AccountNotRecognisedComponent implements OnInit {
  //config variables
  usingProject:projects;

  IsExpertiseExchange:boolean = false; 

  title = 'The log in that you have just used is not recognised';
  message: string;
  firstName: string;
  lastName: string;
  email: string;
  password:string;
  smtype:string='';

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.usingProject = globals.USINGPROJECT;
    this.IsExpertiseExchange= this.usingProject == projects.ExpertiseExchange;
    
    this.message = 'This account is not recognized';
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    

    // Get Root Parameters
    this.route.paramMap.subscribe(params => {
      this.email = params.get('email');
      this.firstName = params.get('firstName');
      this.lastName =  params.get('lastName');

      if( params.get('password')){
        this.password = params.get('password');
      }

      if( params.get('smtype')){
        this.smtype = params.get('smtype');
      }

    });

  }
 
  LinkExistingAccount(): void {
    if (this.email === '' && this.firstName === '' && this.lastName === '') {
      this.router.navigate(['login']);
    } else {
      this.router.navigate(['login', {signupType: 'LOGIN', linkingemail:this.email,linkingpassword:this.password , linkingsmtype:this.smtype}]); 
    }
  }

  LogingAnotherAccount(): void {
    this.router.navigate(['login']);
  }

}
