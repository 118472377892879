//Importing the libraries
import { Injectable } from '@angular/core';
import { Http, Response, URLSearchParams, RequestOptions, Headers } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/throw';

//Importing core classes
import { UserDatabaseDetails } from '../core/userdatabaseDetails';
import { LinkledinMember } from '../core/linkedinMember';
import * as globals from '../core/common';
import { EmailDetails } from '../core/emailDetails';
import { ChangeEmailDetails } from '../core/changeEmailDetails';
import { LoginDetails } from '../core/loginDetails';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

  //The webapi controller urls
  private getIsEmailExistsUrl = globals.signupServerBase + '/api/SignUpApi/checkEmailAlreadyExists';
  private getIsSitenameExistsUrl = globals.signupServerBase + '/api/SignUpApi/checkSiteNameAlreadyExists';
  private setRgisterUserUrl = globals.signupServerBase + '/api/SignUpApi/registerUser';
  private setDeploSiteUrl = globals.signupServerBase + '/api/SignUpApi/deploySite';
  private setDeploDatabaseUrl = globals.signupServerBase + '/api/SignUpApi/deployDatabase';
  private getLinkedinUserDetails = globals.signupServerBase + '/api/Linkedin/GetPersonData';
  private setSendEmailUrl = globals.signupServerBase + '/api/SignUpApi/sendEmailGet';
  private setChangeEmailUrl = globals.signupServerBase + '/api/SignUpApi/ChangeEmail';
  private getIsLinkedEmailExistsUrl = globals.signupServerBase + '/api/SignUpApi/IsLinkedEmailExists';
  private setLinkedDetails = globals.signupServerBase + '/api/SignUpApi/InsertLinkedDetails';
  private EESubscriptioncreateUrl = globals.signupServerBase + '/api/EESubscription/create';

  //Global variables
  headers: any;

  //Constructor of the class
  constructor(private _http: Http) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  
  InsertLinkedEmailDetails(parentEmail: string, parentPassword: string, parentSMType: string, linkedEmail: string, linkedPassword: string, linkedSMType: string){
    let myparams = new URLSearchParams();
    myparams.append("parentEmail", parentEmail);
    myparams.append("parentPassword", parentPassword);
    myparams.append("parentSMType", parentSMType);
    myparams.append("linkedEmail", linkedEmail);
    myparams.append("linkedPassword", linkedPassword);
    myparams.append("linkedSMType", linkedSMType);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.setLinkedDetails, options)
      .map((response: Response) => <boolean>response.json())
      .catch(this.handleError);
  }

  IsLinkedEmailExists(userEmail: string): Observable<boolean> {
    let myparams = new URLSearchParams();
    myparams.append("userEmail", userEmail);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.getIsLinkedEmailExistsUrl, options)
      .map((response: Response) => <boolean>response.json())
      .catch(this.handleError);
  }

  //Changing the email of the user
  ChangeEmail(userID: string, password: string, email: string, firstName: string, lastName: string) {
    let myparams = new URLSearchParams();
    myparams.append("userID", userID);
    myparams.append("password", password);
    myparams.append("email", email);
    myparams.append("firstName", firstName);
    myparams.append("lastName", lastName);
    let options = new RequestOptions({ params: myparams });
    
    return this._http.get(this.setChangeEmailUrl, options)
      .map((response: Response) => <LinkledinMember>response.json())
      .catch(this.handleError);
  }

  //Sending email to the user
  SetSendEmail(emailDetails: EmailDetails) {

    let data = new Object();
    data["sitename"] = emailDetails.SiteName;
    data["userID"] = emailDetails.UserId;
    data["password"] = "test";
    data["trialDays"] = emailDetails.TrialDays;
    data["accountType"] = emailDetails.AccountType;
    data["type"] = emailDetails.EmailType;

    let myparams = new URLSearchParams();
    myparams.append("sitename", emailDetails.SiteName);
    myparams.append("userID", emailDetails.UserId);
    myparams.append("trialDays", emailDetails.TrialDays);
    myparams.append("accountType", emailDetails.AccountType);
    myparams.append("type", emailDetails.EmailType);
    myparams.append("password","test");
    
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.setSendEmailUrl, options)
      .map((response: Response) => <any>response.json())
      .catch(this.handleError);
  }

  //Getting the linked in user details
  GetLinkedinUserDetails(token: string): Observable<LinkledinMember> {
    let myparams = new URLSearchParams();
    myparams.append("token", token);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.getLinkedinUserDetails, options)
      .map((response: Response) => <LinkledinMember>response.json())
      .catch(this.handleError);
  }

  //Deploying the database
  DeployDatabase(userDatabaseDetails: UserDatabaseDetails): Observable<string> {

    switch (userDatabaseDetails.SignUpType) {
      case "EMAIL":
        userDatabaseDetails.SignUpType = "";
        break;
      case "GOOGLE":
        userDatabaseDetails.SignUpType = "google";
        break;
      case "FACEBOOK":
        userDatabaseDetails.SignUpType = "facebook";
        break;
      case "LINKEDIN":
        userDatabaseDetails.SignUpType = "linkedin";
        break;
      case "TWITTER":
        userDatabaseDetails.SignUpType = "twitter";
        break;
    }

    let myparams = new URLSearchParams();
    myparams.append("userID", userDatabaseDetails.UserId);
    myparams.append("domain", userDatabaseDetails.Domain);
    myparams.append("password", userDatabaseDetails.Password);
    myparams.append("trialDays", userDatabaseDetails.TrialDays);
    myparams.append("accountType", userDatabaseDetails.AccountType);
    myparams.append("siteName", userDatabaseDetails.SiteName);
    myparams.append("mailId", userDatabaseDetails.MailId);
    myparams.append("filelocation", userDatabaseDetails.FileLocation);
    myparams.append("smType", userDatabaseDetails.SignUpType);
    let options = new RequestOptions({ params: myparams });



    return this._http.get(this.setDeploDatabaseUrl, options)
      .map((response: Response) => <string>response.json())
      .catch(this.handleError);
  }

  //Deploying the site
  DeploySite(userId: string, sitename: string): Observable<string> {
    let myparams = new URLSearchParams();
    myparams.append("userID", userId);
    myparams.append("sitename", sitename);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.setDeploSiteUrl, options)
      .map((response: Response) => <string>response.json())
      .catch(this.handleError);
  }

  //Registering the user
  RegisterUser(firstName: string, lastName: string, email: string, registerType: string): Observable<string> {
    let myparams = new URLSearchParams();
    myparams.append("name", firstName + " " + lastName);
    myparams.append("email", email);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.setRgisterUserUrl, options)
      .map((response: Response) => <string>response.json())
      .catch(this.handleError);
  }

  //API call to check if the email exists
  IsEmailExists(userEmail: string): Observable<boolean> {
    let myparams = new URLSearchParams();
    myparams.append("email", userEmail);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.getIsEmailExistsUrl, options)
      .map((response: Response) => <boolean>response.json())
      .catch(this.handleError);
  }

  //API call to check if the sitename already exists
  IsSitenameExists(siteName: string): Observable<boolean> {
    let myparams = new URLSearchParams();
    myparams.append("sitename", siteName);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.getIsSitenameExistsUrl, options)
      .map((response: Response) => <boolean>response.json())
      .catch(this.handleError);
  }

  //API call to check if the sitename already exists
  EESubscriptioncreate(key: string): Observable<LoginDetails> {
    let myparams = new URLSearchParams();
    myparams.append("key", key);
    let options = new RequestOptions({ params: myparams });

    return this._http.get(this.EESubscriptioncreateUrl, options)
      .map((response: Response) => <LoginDetails>response.json())
      .catch(this.handleError);
  }
  //The function of handling the error
  private handleError(error: Response) {
    console.log("Error in contact service");
    console.log(error);
    return Observable.throw(error.json().error || 'Server error');
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

}
