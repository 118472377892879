// Importing libraries
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Importing core classes
import { EmailDetails } from 'src/app/core/emailDetails';

//Importing service classes
import { SignupService } from 'src/app/service/signup.service';

//importing the common model for the config changes
import * as globals from 'src/app/core/common';

//importing the enum model
import {projects} from 'src/app/enum/projects';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.css'],
  providers: [SignupService]
})
export class EmailVerificationComponent implements OnInit {
  //config variables
  usingProject:projects;

  IsExpertiseExchange:boolean = false; 

  title = 'Check your inbox to finish sign up';
  subtitle = 'Check your email finish signing up. We sent to verification link to you at:';
  email: string;
  siteName: string;
  emailDetails: EmailDetails;
  userId:string;

  password:string;

  constructor(private route: ActivatedRoute, private router: Router, private signupService: SignupService) { }

  ngOnInit() {
    this.usingProject = globals.USINGPROJECT;
    this.IsExpertiseExchange= this.usingProject == projects.ExpertiseExchange;

    this.email = '';
    this.siteName = '';

    this.route.paramMap.subscribe(params => {
      this.email = params.get('email');
      this.password = params.get('password');
      this.emailDetails = <EmailDetails>JSON.parse(atob(params.get("emailObject")));
      this.siteName = this.emailDetails.SiteName;
    });
  }

  ChangeEmailAddress() {
    this.router.navigate(['account-create', {signupType: 'EMAIL', siteName: this.siteName, userID:this.emailDetails.UserId}]);
  }

  //Resending the email
  ResendEmail(){
    //Calling the send email service method
    this.signupService.SetSendEmail(this.emailDetails).subscribe(
      data=>{
        console.log("Email resent");
      }
    );
  }

  GoBackToExpertiseExchange(){
    //Redirect to the login page
    this.router.navigate(['login', { email: this.email, password: this.password, signupType:"EMAIL", loginwithemail:"TRUE" }]);
  }

}
