import { Component, OnInit, ViewChild } from '@angular/core';
import { EesubscriptionService } from 'src/app/service/eesubscription.service';

@Component({
  selector: 'app-eecontact-list',
  templateUrl: './eecontact-list.component.html',
  styleUrls: ['./eecontact-list.component.css'],
  providers: [EesubscriptionService]
})
export class EecontactListComponent implements OnInit {

  //Upload varibales
  @ViewChild('fileInput') fileInput;
  splittedList: string[] = [];
  //Allowing file extention type
  allowFileExtensions: string[] = ['XLSX','XLS'];
  //Variable to set enable the button
  disableUploadButton:boolean = true;
  //Variable to display the error message
  displayErrorMesg:boolean = false;
  //Variable to display the confirmation
  displayConfirmation:boolean = false;

  constructor(private eesubscriptionService: EesubscriptionService) { }

  ngOnInit() {
  }

  uploadFile() {
    let formData = new FormData();
    formData.append('upload', this.fileInput.nativeElement.files[0])

    this.eesubscriptionService.UploadExcel(formData).subscribe(result => {
      this.displayConfirmation = true;
    });

  }

  handleUpload(event) {
    let files = event.target.files;

    //Getting the file name
    let uploadedFileName = files[0]['name'];

    //Splitting with the dot
    this.splittedList = uploadedFileName.split(".");

    //Getting the file extension
    let fileExtension = this.splittedList[this.splittedList.length - 1];

    //Setting the logic to check the availability if the extension
    const checkRoleExistence = roleParam => this.allowFileExtensions.some(role => role.toUpperCase() == roleParam.toUpperCase())

    //Checking if the extension available
    this.disableUploadButton = !checkRoleExistence(fileExtension);
    this.displayErrorMesg = this.disableUploadButton;

  }


  //Sending the emails
  sendEmail(){
    this.eesubscriptionService.SendEmails().subscribe(
      data=>{
        this.displayConfirmation = false;
        this.disableUploadButton = true;
      }
    );
  }

}
