import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service';
import { UserEmail } from 'src/app/core/userEmail';
import { UserMobile } from 'src/app/core/userMobile';

@Component({
  selector: 'app-authentication-verification',
  templateUrl: './authentication-verification.component.html',
  styleUrls: ['./authentication-verification.component.css'],
  providers: [AuthenticationService]
})
export class AuthenticationVerificationComponent implements OnInit {

  //Variables to store the selected email
  selectedEmail: UserEmail;
  //Variable to hold the emails
  emailList: any[] = [
    { UserEmail: 0, Email: "john@smith.com" },
    { UserEmail: 1, Email: "rashmalat@gmail.com" }
  ]
  // Email List
  userEmailList: UserEmail[] = [];


  //Mobile is disabled
  isMobileDisable: boolean = false;
  selectedValueTemp: string = "valTemp";
  displayDisableClikButtom: boolean = false;
  // Person Id
  personID: number;
  //Mobile list
  mobileList: UserMobile[] = [];
  maskedNumber: string;

  authId:number;
  navigateString:string;

  selectEmailOption: string = "";;
  selectSMSOption: string = "";

  disableContinueBtn:boolean = true;

  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    //Retereive the params from routing
    this.route.paramMap.subscribe(params => {

      if (params.get('personID')) {
        this.personID = +params.get('personID');
      }

      if (params.get('authId')) {
        this.authId = +params.get('authId');
      }

      if (params.get('navigateString')) {
        this.navigateString = params.get('navigateString');
      }

    });

    //Take the user emails
    this.authenticationService.GetUserEmails(this.personID).subscribe(
      data => {
        this.userEmailList = data;

        this.emailList = [];
        //Pipulate the dropdown
        for (let i = 0; i < this.userEmailList.length; i++) {
          this.emailList.push(this.userEmailList[i]);
        }

        this.selectedEmail = this.emailList[0];

        //Does the user has a mobile number
        this.authenticationService.GetUserMobiles(this.personID).subscribe(
          data => {
            this.mobileList = data;
            if (this.mobileList.length === 0) {
              this.isMobileDisable = true;
            } else {
              this.isMobileDisable = false;
              this.maskedNumber = this.mobileList[0].Mobile.substring(this.mobileList[0].Mobile.length - 3);
            }
          }
        );

      }
    );

  }

  onClickRadioButton(){
    if(this.selectEmailOption != "" || this.selectSMSOption != ""){
      this.disableContinueBtn = false;
    }else{
      this.disableContinueBtn = true;
    }
  }

  onClickContinueFunction() {
    if (this.selectEmailOption) {
      if (this.userEmailList.length == 1) {
        this.router.navigate(['authenticate-code', { personID: this.personID, type: "EMAIL", valueID: this.userEmailList[0].EmailId, valueString: this.userEmailList[0].Email, authId: this.authId, navigateString: this.navigateString }]);
      } else {
        this.router.navigate(['authenticate-code', { personID: this.personID, type: "EMAIL", valueID: this.selectedEmail.EmailId, valueString: this.selectedEmail.Email, authId: this.authId, navigateString: this.navigateString }]);
      }
    }

  }


  closePopError() {
    this.displayDisableClikButtom = false;
  }

  disableOnClick() {
    this.displayDisableClikButtom = true;
  }
}
