// Importing the libraries
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SocialLoginModule, AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider, LinkedinLoginProvider } from 'ng4-social-login';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FormsModule, ReactiveFormsModule   } from '@angular/forms';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { CountdownTimerModule } from 'ngx-countdown-timer';


//Importing prime ng libraries
import {DropdownModule} from 'primeng/dropdown';
import {RadioButtonModule} from 'primeng/radiobutton';

// Importing the components
import { AccountCreateComponent } from './components/account-create/account-create.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { AccountNotRecognisedComponent } from './components/account-not-recognised/account-not-recognised.component';

import { NgxPasswordToggleModule } from 'ngx-password-toggle';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EEAutoSiteCreationComponent } from './components/eeauto-site-creation/eeauto-site-creation.component';
import { EecontactListComponent } from './components/eecontact-list/eecontact-list.component';
import { AuthenticateEmailComponent } from './components/authenticate-email/authenticate-email.component';
import { AuthenticateCodeComponent } from './components/authenticate-code/authenticate-code.component';
import { AuthenticationVerificationComponent } from './components/authentication-verification/authentication-verification.component';
import { CookieService } from 'ngx-cookie-service';


// Configurations on social media
const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('500677938727-21vdgc9d8setkf4qp6ev2c6seceuva73.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('2237795703200004')
  },
  {
    id: LinkedinLoginProvider.PROVIDER_ID,
    provider: new LinkedinLoginProvider('818w5ceuwhconj')
  }
  ], false);

export function provideConfig()
{
  return config;
}



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    SignUpComponent,
    AccountCreateComponent,
    EmailVerificationComponent,
    AccountNotRecognisedComponent,
    DashboardComponent,
    EEAutoSiteCreationComponent,
    EecontactListComponent,
    AuthenticateEmailComponent,
    AuthenticateCodeComponent,
    AuthenticationVerificationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SocialLoginModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    HttpModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    NgxPasswordToggleModule ,
    DropdownModule,
    CountdownTimerModule.forRoot(),
    RadioButtonModule
  ],
  providers: [
    {provide: AuthServiceConfig, useFactory: provideConfig},
    CookieService
  ],
  bootstrap: [AppComponent],
  entryComponents:[]
})
export class ExpAppModule { }
