// Importing libraries
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';

// Importing services
import { SignupService } from 'src/app/service/signup.service';
import { ComponentFactoryResolver } from '@angular/core/src/render3';
import { UserDatabaseDetails } from 'src/app/core/userdatabaseDetails';
import { EmailDetails } from 'src/app/core/emailDetails';
import { LoginService } from 'src/app/service/login.service';

//importing the common model for the config changes
import * as globals from 'src/app/core/common';

//importing the enum model
import {projects} from 'src/app/enum/projects'

export interface FormModelRecap {
  captcha?: string;
}

@Component({
  selector: 'app-account-create',
  templateUrl: './account-create.component.html',
  styleUrls: ['./account-create.component.css'],
  providers: [SignupService, LoginService]
})
export class AccountCreateComponent implements OnInit {
  //config variables
  usingProject:projects;

  IsExpertiseExchange:boolean = false; 
  

  VlidateSitename;
  // Global variables
  recaptchaData: any;
  // Variables to store user details
  firstName: string;
  lastName: string;
  signupType: string;
  password: string = "";
  trialDays: string = "14";
  // variable to display the recaptcha
  displayRecaptcha: boolean = true;
  //variable to enable and disbale the signup button
  signupDisabled: boolean = true;
  //variable for snapzing
  showLoading: boolean = false;
  hiddenErrorEmailValidationMessage: boolean = true;

  accountCreateFrom: FormGroup;
  submitted = false;

  exits: boolean;
  siteName: boolean;

  title = 'Create your account';
  subtitle = 'Complete your details';
  emailAddress: string = '';
  userName: string = '';
  yoursitename: string = '';
  errorMessage: string = '';
  errorMessageSiteName: string = '';
  recaptureMessage: string = '';
  userId: string = '';
  disableSignupButton: boolean = true;
  disableDifferentExpectationalAccountLable: boolean = false;
  //siteKey: string ="6LfU1nAUAAAAAKjlxhf5k7LoB7SJtca8rslWP8Mn";
  hiddenFullName: boolean;
  hiddenAllreadySignUp: boolean;

  // Warning and Error Messages
  siteNameDisplayMessage: string;
  emailDisplayMessage: string;
  //commonErrorMessage: string;  
  passwordInformationMessage: string;
  sitePatternErrorMessage: string;
  siteNameLimitationMessage: string;
  emailValidationMessage: string;
  passwordStrengthMessage: string;

  // Show password strenth progress bar
  displayPasswordStrength: boolean;
  displayPasswordStrengthFill: boolean;

  // Hide site information
  hiddenSiteInformation: boolean;
  // Hide password information  
  hiddenPasswordInformation: boolean;
  // Hide email validation
  hiddenEmailValidationMessage: boolean;
  // Hide site name limitation
  hiddenSiteNameLimitation: boolean;
  // Hide full name
  hiddenCommonErrorValidationFullName: boolean;
  // Hide Password strength 
  hiddenpasswordStrengthValidation: boolean;
  // Show site name When empty
  hiddenCommonErrorValidationSiteName: boolean;
  // Hide email when empty
  hiddenCommonErrorValidationEmail: boolean;
  // Hide password when empty
  hiddenCommonErrorValidationPassword: boolean;
  // Hide site name pattern error
  hiddenSitePatternErrorMessage: boolean;
  // Hide site name validtion form db
  hiddenErrorMessageSiteName: boolean;

  //Signup method
  createUserEmailMethod: boolean = false;
  linkScoailMediToEmail: boolean = false;

  // Hiddeden Password Strength Bar
  hiddenpasswordStrengthBar: boolean;

  // Hide hr
  hiddenHR: boolean;

  smid: string;

  // Constructor of the method
  constructor(private formBuilder: FormBuilder, private router: Router, private signupService: SignupService, private route: ActivatedRoute, private loginService: LoginService) { }

  // The ngOnInit method of the component
  ngOnInit() {
    this.usingProject = globals.USINGPROJECT;
    this.IsExpertiseExchange= this.usingProject == projects.ExpertiseExchange;

    this.hiddenHR = false;
    this.hiddenpasswordStrengthBar = true;
    this.hiddenErrorMessageSiteName = true;
    this.displayPasswordStrength = false;
    this.displayPasswordStrengthFill = false;

    this.hiddenSitePatternErrorMessage = true;
    this.hiddenCommonErrorValidationPassword = true;
    this.hiddenCommonErrorValidationEmail = true;
    this.hiddenCommonErrorValidationSiteName = true;
    this.hiddenpasswordStrengthValidation = true;
    this.hiddenCommonErrorValidationFullName = true;
    this.hiddenSiteNameLimitation = true;
    this.hiddenEmailValidationMessage = true;
    this.hiddenSiteInformation = true;
    this.hiddenPasswordInformation = true;
    this.passwordStrengthMessage = '';
    this.siteNameLimitationMessage = '';
    this.hiddenFullName = false;
    this.hiddenAllreadySignUp = false;
    this.recaptureMessage = '';

    this.accountCreateFrom = this.formBuilder.group({
      yoursitename: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators]],
      fullName: ['', Validators.required],
      recaptcha: ['', Validators.required]
    });

    // Retreiving the parameters through routing
    this.route.paramMap.subscribe(params => {

      // Retreiving the email
      if (params.get('signupType')) {
        this.signupType = params.get('signupType');

        // Checking the signup type
        if (this.signupType === 'EMAIL') {
          this.displayRecaptcha = true;
        } else {
          this.displayRecaptcha = false;
        }

      }

      if (params.get('email')) {
        this.emailAddress = params.get('email');
      }

      // Retreiving the userId
      if (params.get('userID')) {
        this.userId = params.get('userID');
      }

      if (params.get('userID')) {
        this.userId = params.get('userID');
      }

      // Retreiving the signup type
      if (params.get('smid')) {
        this.smid = params.get('smid');
      }

      // Retreiving the first name
      if (params.get('firstName')) {
        this.firstName = params.get('firstName');
      }

      // Retreiving the last name
      if (params.get('lastName')) {
        this.lastName = params.get('lastName');
      }

      // Setting the user name
      if (params.get('firstName') && params.get('lastName')) {
        this.userName = this.firstName + ' ' + this.lastName;
      }

      // Retreiving the sitename
      if (params.get('siteName')) {
        this.yoursitename = params.get('siteName');
        this.siteName = false;
      }
      else {
        this.siteName = true;
      }



      //this.yoursitename = params.get("id3");

      //console.log(this.emailAddress,this.userName,this.yoursitename);

      if (this.emailAddress != null && this.emailAddress != '' && this.userName != '' && this.userName != null) {
        //console.log(1);
        this.signupDisabled = false;
        this.title = "Claim your site";
        this.subtitle = "Create site name for your account";
        this.accountCreateFrom.controls['password'].disable();
        this.accountCreateFrom.controls['email'].disable();
        this.accountCreateFrom.controls['recaptcha'].disable();
        this.siteName = false;
        this.exits = false;
      }
      else {
        //console.log(2);

        this.title;
        this.subtitle;
        this.exits = true;
        this.siteName = false;
        this.disableDifferentExpectationalAccountLable = true;
        this.linkScoailMediToEmail = false;

        if (this.yoursitename != '' && this.emailAddress == '' && this.userName == '') {
          //console.log(3);
          this.siteName = true;
          this.displayRecaptcha = false;
          this.accountCreateFrom.controls['recaptcha'].disable();
        }
      }

      // Create password to login using exiting email
      if (this.signupType === 'EMAIL' && this.yoursitename !== '' && this.emailAddress !== '') {
        //console.log(4);
        this.signupDisabled = false;
        this.title = "Create your password";
        this.subtitle = "Enable you to login using email";
        this.accountCreateFrom.controls['email'].disable();
        this.accountCreateFrom.controls['recaptcha'].disable();
        this.accountCreateFrom.controls['fullName'].disable();
        this.siteName = true;
        this.exits = true;
        this.displayRecaptcha = false;
        this.hiddenFullName = true;
        this.disableDifferentExpectationalAccountLable = false;
        this.hiddenAllreadySignUp = true;
        this.createUserEmailMethod = true;
      }

      if (this.createUserEmailMethod === false && this.linkScoailMediToEmail == false) {
        this.disableSignupButton = false;
      }

    })
  }

  get accountCreateFromList() { return this.accountCreateFrom.controls; }

  ClearMessage() {
    this.recaptureMessage = '';
  }

  // Connect Email verification page & Send Email to the inbox
  SignUpOnClickFunction(): void {

    console.log("In")
    console.log(this.createUserEmailMethod + " " + this.linkScoailMediToEmail);
    if (this.createUserEmailMethod === false && this.linkScoailMediToEmail == false) {
      //Change password and site creation

      this.submitted = true;
      if (this.accountCreateFrom.controls['recaptcha'].value == undefined) {
        this.recaptureMessage = "Please check Re Capture";
      }

      // stop here if form is invalid
      if (this.accountCreateFrom.invalid) {
        // return;
        console.log("Form is invalid");
      } else {
        //To check the action type
        if (this.userId === '') {
          //Singup function
          this.SingUpUserFunction();
        } else {
          //Change email and password function
          this.ChangeEmailFunction(this.userId, this.password, this.emailAddress);
        }

      }
    } else if (this.createUserEmailMethod == true) {
      //Creating the password and linking the email to social media
      this.CreatePasswordAndLink(this.emailAddress, this.password);

    } else if (this.linkScoailMediToEmail === true) {
      //Linking the social media to email

    }
  }

  //Create password function
  CreatePasswordAndLink(email: string, password: string) {
    //Add the password

    //Insert to linked details


    this.loginService.SetEmailMethod(email, password).subscribe(
      data => {
        console.log("Add email method: " + data);
        //Perform login
        this.router.navigate(['login', { email: email, password: password }]);
      }
    );
  }




  //Change email function
  ChangeEmailFunction(userID: string, password: string, email: string) {
    this.showLoading = true;
    //Spliting the full name into two
    var splittedName = this.userName.split(" ");
    var firstname = "";
    var lastname = "";

    //Indentifying the first and last names
    if (splittedName.length == 1) {
      firstname = splittedName[0];
    } else if (splittedName.length > 2) {
      let indexOfSpace = this.userName.indexOf(" ");
      firstname = this.userName.substring(0, indexOfSpace);
      lastname = this.userName.substring(indexOfSpace + 1);
    } else {
      firstname = splittedName[0];
      lastname = splittedName[1];
    }


    forkJoin(this.ChangeEmail(userID, password, email, firstname, lastname)).subscribe(
      results => {
        console.log("Change Email: " + results[0]);

        //Send email
        //Send email
        const emailDetails: EmailDetails = {
          UserId: userID,
          AccountType: this.IsExpertiseExchange? "EEF": "INDIVIDUAL" ,
          EmailType: "EMAIL$VERIFY",
          SiteName: this.yoursitename,
          TrialDays: this.trialDays
        };

        this.signupService.SetSendEmail(emailDetails).subscribe(
          data => {
            console.log("Send email");
            console.log(data);
            //Stopping the snapsing loading ui
            this.showLoading = false;
            //Redirect to the email sent page
            this.router.navigate(['email-verification', this.emailAddress, btoa(JSON.stringify(emailDetails))]);
          }
        );

      }
    );
  }

  //Performing the signup function
  SingUpUserFunction() {
    this.showLoading = true;
    //Spliting the full name into two
    var splittedName = this.userName.split(" ");
    var firstname = "";
    var lastname = "";

    //Indentifying the first and last names
    if (splittedName.length == 1) {
      firstname = splittedName[0];
    } else if (splittedName.length > 2) {
      let indexOfSpace = this.userName.indexOf(" ");
      firstname = this.userName.substring(0, indexOfSpace);
      lastname = this.userName.substring(indexOfSpace + 1);
    } else {
      firstname = splittedName[0];
      lastname = splittedName[1];
    }

    //Register the new user
    this.signupService.RegisterUser(firstname, lastname, this.emailAddress, this.signupType).subscribe(
      data => {
        console.log("Register User");
        console.log(data)
        let userId = data;
        //Deploying the site
        this.signupService.DeploySite(userId, this.yoursitename).subscribe(
          data => {
            console.log("Deploying the site");
            console.log(data);
            if (data == "Successfully Added.") {
              //Deploying the database

              if (this.signupType !== "EMAIL") {
                this.password = this.smid;
              }

              const userDatabaseDetails: UserDatabaseDetails = {
                AccountType: this.IsExpertiseExchange? "EEF":"INDIVIDUAL",
                UserId: userId,
                Domain: this.yoursitename,
                Password: this.password,
                TrialDays: this.trialDays,
                MailId: "",
                FileLocation: "",
                SignUpType: this.signupType,
                SiteName: this.yoursitename
              };

              this.signupService.DeployDatabase(userDatabaseDetails).subscribe(
                data => {
                  console.log("Deploy Database");
                  console.log(data);

                  //Send email
                  const emailDetails: EmailDetails = {
                    UserId: userId,
                    AccountType: this.IsExpertiseExchange? "EEF":"INDIVIDUAL",
                    EmailType: "EMAIL$VERIFY",
                    SiteName: this.yoursitename,
                    TrialDays: this.trialDays
                  };

                  //Stopping the snapsing loading ui
                  this.showLoading = false;

                  this.signupService.SetSendEmail(emailDetails).subscribe(
                    data => {
                      console.log("Send email");
                      console.log(data);

                      //Checking the signup type
                      if (this.signupType == "EMAIL") {
                        //Redirect to the email sent page
                        if(this.IsExpertiseExchange){
                          //Auto login
                          this.router.navigate(['login', { email: this.emailAddress, password: this.password, signupType: "EMAIL", loginwithemail: "TRUE" }]);
                        }
                        else{
                          this.router.navigate(['email-verification', this.emailAddress,this.password, btoa(JSON.stringify(emailDetails))]);
                        }

                      } else {
                        //Redirect to the login page
                        this.router.navigate(['login', { email: this.emailAddress, password: this.password, signupType: this.signupType, loginwithemail: "TRUE" }]);
                      }


                    }
                  );

                }
              );
            } else {
              //Stopping the snapsing loading ui
              this.showLoading = false;
              console.log("Error in deploying the site");
            }
          }
        );
      }
    );
  }

  //Validating the user entered sitename
  ValidateSitenameOnInput() {

    if (this.accountCreateFrom.get('yoursitename').hasError('pattern')) {
      //Site name has an error in the patten
      this.hiddenSitePatternErrorMessage = false;
      this.sitePatternErrorMessage = 'Site name contains invalid characters';
      return;
    }


    //Converting to lower case
    this.yoursitename = this.yoursitename.toLocaleLowerCase();
    //Calling the shared method to check if the sitename exists
    forkJoin(this.IsSiteNameExists(this.yoursitename)).subscribe(
      results => {
        //console.log("Sitename exists");
        //console.log(results[0]);
        if (results[0] == false) {
          this.hiddenErrorMessageSiteName = true;
          this.errorMessageSiteName = "";
          //Enabiling the signup button
          this.signupDisabled = false;
        } else {
          this.hiddenErrorMessageSiteName = false;
          this.errorMessageSiteName = "Site name already exits";
          //Disabling the sign up button
          this.signupDisabled = true;
        }
      }
    );
  }

  //Validating the user entered email
  ValidateEmailOnFocusOut() {
    //Check for the patten
    this.hiddenEmailValidationMessage = true;
    //this.hiddenCommonErrorValidationEmail = false;

    if (this.emailAddress.length === 0) {
      this.hiddenCommonErrorValidationEmail = true;
    }

    //Calling the service method to check if the email exists
    forkJoin(this.IsEmailExists(this.emailAddress), this.IsLinkedEmailExists(this.emailAddress)).subscribe(
      results => {
        // console.log("Email exists: " + results[0]);
        if (results[0] == false && results[1] == false) {
          this.errorMessage = "";
          this.hiddenErrorEmailValidationMessage = true;
          //Enabiling the signup button
          this.signupDisabled = false;
        } else {
          //Display error message
          this.errorMessage = "Email already exits";
          this.hiddenErrorEmailValidationMessage = false;
          //Disabling the sign up button
          this.signupDisabled = true;
        }
      }
    );
    //}

  }


  //----------------------------- Service calls ---------------------------------//

  //Calling whether email exists service call
  IsEmailExists(useremail: string) {
    return this.signupService.IsEmailExists(useremail);
  }

  IsLinkedEmailExists(useremail: string) {
    return this.signupService.IsLinkedEmailExists(useremail);
  }

  //Calling whether sitename exists service call
  IsSiteNameExists(sitename: string) {
    return this.signupService.IsSitenameExists(sitename);
  }

  //Changing the email of the user
  ChangeEmail(userID: string, password: string, email: string, firstName: string, lastName: string) {
    return this.signupService.ChangeEmail(userID, password, email, firstName, lastName);
  }

  //----------------------- End of Service calls --------------------------------//

  //--------------------------- Sitename ------------------------------------------//

  // User click the site name display message
  DisplaySiteNameDetailsOnFocusIn() {
    this.hiddenSiteInformation = false;
    this.siteNameDisplayMessage = 'Your expertential site name cannot be changed. The name cannot use capitals, you can’t have space in between any characters. Do not use any other organization’s name of trademark. We recommended using your relevant organization name or personal name';
    //this.commonErrorMessage = '';

    this.hiddenCommonErrorValidationSiteName = true;
    this.hiddenCommonErrorValidationEmail = true;
    this.hiddenCommonErrorValidationPassword = true;
    this.hiddenCommonErrorValidationFullName = true;

  }

  ClearSiteNameDetailsOnFocusOut() {
    this.hiddenSiteInformation = true;
    this.siteNameDisplayMessage = '';
    this.hiddenSiteNameLimitation = true;
    if (this.yoursitename.length === 0) {
      this.hiddenCommonErrorValidationSiteName = false;
    }
    else {
      this.hiddenCommonErrorValidationSiteName = true;
    }
    this.hiddenSitePatternErrorMessage = true;
  }

  // Display site name charactor limit
  ShowSiteNameLimitatiOnKeyPress() {
    this.hiddenSiteInformation = true;
    this.hiddenSiteNameLimitation = false;
    this.hiddenCommonErrorValidationSiteName = true;

    if (this.yoursitename.length < 3) {
      this.siteNameLimitationMessage = 'This expertential site name should be more than 3 characters';
      this.siteNameDisplayMessage = '';
    }
    else {
      this.siteNameLimitationMessage = '';
      this.hiddenSiteNameLimitation = true;
    }

    if (this.yoursitename.length === 0) {
      this.hiddenSitePatternErrorMessage = true;
    }

  }

  // Display error text box when empty 
  CommonErrorMessage() {
    //this.commonErrorMessage ='Please fill out this field';
    this.siteNameLimitationMessage = '';
  }

  //--------------------------- Email ------------------------------------------//
  // Email validation
  CheckEmailNameOnKeyPress() {
    if (this.accountCreateFrom.get('email').hasError('pattern')) {
      //Email name has an error in the patten
      this.hiddenEmailValidationMessage = false;
      this.emailValidationMessage = 'Invalid email address';
      return;
    }
  }

  CheckEmailNameOnFocusIn() {
    this.hiddenEmailValidationMessage = true;
    if (this.emailAddress.length === 0) {
      this.hiddenCommonErrorValidationEmail = false;
      ////this.commonErrorMessage();
    }
  }

  //--------------------------- Full Name ------------------------------------------// 
  // Validate Full Name
  FullNameOnFocusOut() {
    if (this.userName.length === 0) {
      this.hiddenCommonErrorValidationFullName = false;
      //this.commonErrorMessage();
    }
    else {
      this.hiddenCommonErrorValidationFullName = true;
    }
  }

  FullNameOnFocusIn() {

  }

  //--------------------------- Password ------------------------------------------// 

  // User click the password display message
  DisplayPasswordInformationOnFocusIn() {
    this.hiddenPasswordInformation = false;
    this.passwordInformationMessage = 'Your password MUST be between 8 and 12 characters, contain at least one number and at least one special character (e.g. @ # $, etc.)';


    this.hiddenCommonErrorValidationSiteName = true;
    this.hiddenCommonErrorValidationPassword = true;
    this.hiddenCommonErrorValidationFullName = true;

  }

  // Cleare Password focus out
  ClearPasswordOnFocusOut() {
    this.hiddenPasswordInformation = true;

    this.siteNameDisplayMessage = '';
    this.passwordInformationMessage = '';
    if (this.password.length === 0) {
      this.hiddenpasswordStrengthValidation = true;
    }
    this.hiddenCommonErrorValidationPassword = false;
  }

  ShowPasswordProgressBar() {

    // Show password strength message
    if (this.password.length < 7) {
      this.hiddenPasswordInformation = true;
      this.hiddenpasswordStrengthValidation = false;

      if ((7 - this.password.length) > 0) {
        this.passwordStrengthMessage = (7 - this.password.length).toString() + ' more characters, At least 1 more symbol';
        this.siteNameDisplayMessage = '';
      }

    } else if (this.password.length > 7) {
      if ((7 - this.password.length) <= 0) {
        this.passwordStrengthMessage = 'Strong Password';
        this.siteNameDisplayMessage = '';
      }
    }

    // -----------------------

    //Show Password Strength

    if (this.password.length === 0) {
      this.hiddenHR = false;
      this.hiddenpasswordStrengthBar = true;
      this.displayPasswordStrength = false;
      this.hiddenpasswordStrengthValidation = true;

    } else if (this.password.length > 0 && this.password.length < 8) {
      this.hiddenHR = true;
      this.hiddenpasswordStrengthBar = false;
      this.displayPasswordStrength = true;
    }
    else {
      this.hiddenHR = true;
      this.hiddenpasswordStrengthBar = false;
      this.displayPasswordStrength = false;
    }
  }

  AlreadyLogin(){
    this.router.navigate(['login']);
  }

}
